// Config values
$platform-name: 'The Katharine House Hospice';

// Colours
$brand-primary: #EA5A5E;
$brand-primary-accessible: #ee0003; /* darkened to make colour contrast with white meet WCAG 2.1 standards */
$brand-secondary: #006082;
$brand-tertiary: #00C6C6;
$brand-quarternary: #83BB00;

$bg-color: #fff;
$bg-dark-primary: $brand-secondary;
$bg-dark-secondary: $brand-quarternary;
$bg-light-primary: #DDEAEE;
$bg-light-secondary: #FEEFBB;

$font-colour: #0C0C0C;
$body-colour: $font-colour;
$border-colour: #ADADAD;

// Gradients
$gradient-blue: radial-gradient(circle, #00C6C6 0%, #006082 100%);
$gradient-green: radial-gradient(circle, #83BB00 0%, #087203 100%);
$gradient-pink: radial-gradient(circle, #FFA6A8 0%, #EB595D 100%);
$gradient-light: linear-gradient(270deg, #FFFFFF 0%, #C5D9E0 100%);

// Greyscale
$black: #000;
$white: #fff;

// Logo
$logo-width: 231px;
$logo-height: 80px;
$logo-mobile-width: 144px;
$logo-mobile-height: 50px;

// Typography
$font-family-base: "Source Sans Pro", sans-serif;

$font-size-base: 18px;
$font-size-large: 20px;
$font-size-small: 14px;

$font-size-h1: 49px;
$font-size-h2: 1.5em;
$font-size-h3: 1.25em;
$font-size-h4: 1.117em;
$font-size-h5: 1em;
$font-size-h6: 0.5em;

$font-weight-normal: normal;
$font-weight-bold: bold;
$line-height-base: 1.5;

$headings-line-height: 1.4;
$headings-colour: $brand-secondary;

// Links
$link-colour: $brand-primary;
$link-colour: $brand-primary-accessible; 

// Transitions
$transition-duration: 0.3s;
$transition-duration-slow: 1.5s;
$transition-curve: ease;

// Layout
$container-max-width: 1280px;
$border-radius: 5px;

// Buttons
$btn-background-colour: $brand-primary;
$btn-text-colour: $white;
$btn-border-radius: 28px;
$btn-padding-x: 27.5px;
$btn-padding-y: 15px;
$btn-font-weight: $font-weight-bold;
$btn-font-size: $font-size-base;
$btn-line-height: 1.25;

// Search
$header-search-margin-right: 15px;

// Search - input
$header-search-input-max-width: 280px;
$header-search-input-margin-right: 0;
$header-search-input-padding: 9.5px 30px;
$header-search-input-placeholder-colour: $body-colour;
$header-search-input-border-radius: 0;
$header-search-input-border-width: 0; // t-r-b-l
$header-search-input-background-colour: tranparent;
$header-search-input-colour: $body-colour;
$header-search-input-focus-background-colour: tranparent;
$header-search-input-focus-colour: $body-colour;

// Search - button
$header-search-button-width: 24px;
$header-search-button-height: 24px;
$header-search-button-background-colour: transparent;
$header-search-button-icon-font-size: 18px;
$header-search-button-icon-colour: $black;
$header-search-button-border-radius: 0;

// Navigation
$nav-background-colour: $bg-dark-primary;

// Top level items
$nav-top-level-item-padding: 20px 15px;
$nav-top-level-item-colour: $white;
$nav-top-level-item-font-weight: $font-weight-bold;
$nav-top-level-item-hover-background-colour: transparent;
$nav-top-level-chevrons-enabled: true;

// Submenus
$nav-submenu-background-colour: $brand-secondary;
$nav-submenu-item-padding: 10px 15px;
$nav-submenu-item-colour: $white;

// Burger button
$burger-btn-background-colour: transparent;
$burger-btn-text-colour: $brand-secondary;

// Nav normal
$nav-normal-mobile-background-colour: $bg-dark-primary;
$nav-normal-mobile-top-level-item-colour: $white;
$nav-normal-mobile-submenu-item-colour: $white;

// Carousel
$carousel-border-radius: 0;
$carousel-details-style: adjacent; // overlay, adjacent or below
$carousel-details-background-colour: transparent;
$carousel-details-max-width: 100%;
$carousel-details-padding: 120px 50px 240px 20px;
$carousel-details-border-radius: 0;
$carousel-heading-colour: $white;
$carousel-heading-font-size: $font-size-h1;
$carousel-summary-colour: $black;
$carousel-summary-font-size: $font-size-base;
$carousel-read-more-background-colour: $brand-primary;

// Carousel controls (left-right buttons)
$carousel-controls-button-size: 56px;
$carousel-controls-button-gap: 20px;
$carousel-controls-button-background-colour: $bg-light-primary;
$carousel-controls-button-border-radius: 50%;
$carousel-controls-position-y: bottom; // top, center, bottom
$carousel-controls-position-x: right; // split, left or right
$carousel-controls-icon-colour: $black;
$carousel-controls-next-icon: "\f0da"; // TODO: These are temporarily suspended
$carousel-controls-prev-icon: "\f0d9"; // TODO: These are temporarily suspended

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;

// Carousel after breakpoint
$carousel-mobile-details-background-colour: transparent;
$carousel-mobile-heading-colour: $carousel-heading-colour;
$carousel-mobile-summary-colour: $carousel-summary-colour;

// Card
$card-border: none;
$card-image-background-colour: transparent;
$card-image-border-radius: $border-radius;
$card-details-padding: 20px 0 0;
$card-details-background-colour: transparent;
$card-heading-font-size: $font-size-h4;
$card-heading-margin-bottom: 10px;
$card-summary-enabled: true;
$card-summary-colour: $brand-secondary;

// Card hover state
$card-heading-colour: $brand-secondary;
$card-hover-heading-colour: $link-colour;
$card-hover-summary-colour: $brand-secondary;
$card-hover-image-opacity: 1;

// Menu admin
$menu-admin-enabled: false;

// Footer
$footer-padding-y: 0;
$footer-background-colour: transparent;
$footer-colour: $body-colour;
$footer-link-decoration: none;
$footer-link-hover-decoration: none;

// Footer admin links
$footer-admin-basket-link-enabled: true;
$footer-admin-login-link-enabled: true;
$footer-admin-manager-link-enabled: true;
$footer-admin-my-details-link-enabled: true;
$footer-admin-logout-link-enabled: true;

// Footer specific social icons
$social-icons-background-colour: $brand-tertiary; // A colour, transparent, or 'brand'
$social-icons-colour: $black; // A colour, or 'brand'
$social-icons-hover-colour: $black; // A colour, or 'brand'
$social-icons-font-size: $font-size-base;
$social-icons-gap: 10px;

// Newsletter
$newsletter-background-colour: $brand-quarternary;
$newsletter-colour: $font-colour;
$newsletter-heading-text-align: left; // left, center, right
$newsletter-heading-margin-bottom: 30px;
$newsletter-button-height: 50px; // Hard code this so you can match it with the inputs
$newsletter-button-background-colour: $brand-primary;
$newsletter-button-colour: $font-colour;
$newsletter-button-border-colour: $brand-primary;

// Newsletter hero - full width form above rest of footer
$newsletter-hero-padding-y: 50px;
$newsletter-hero-contents-max-width: 100%;

// Quick giving panel
$quick-giving-background-colour: $bg-dark-secondary;
$quick-giving-padding: 40px;
$quick-giving-donation-amount-figure-font-size: $font-size-large;
$quick-giving-donation-amount-padding: 15px 25px;
$quick-giving-donation-amount-background-colour: $white;
$quick-giving-donation-amount-figure-colour: $black;
$quick-giving-donation-amount-selected-background-colour: $brand-primary;
$quick-giving-donation-amount-selected-figure-colour: $quick-giving-donation-amount-figure-colour;

// Home features
$home-features-background-colour: transparent;
$home-features-padding-top: 0;
$home-features-padding-bottom: 90px;

// Impact stats
$impact-stats-margin-top: 40px;
$impact-stats-margin-bottom: 100px;
$impact-stats-padding-y: 0;
$impact-stats-background-colour: transparent;
$impact-stats-border-radius: 0;
$impact-stats-text-align: left; // left, center, right

// Heading
$impact-stats-heading-colour: $brand-secondary;
$impact-stats-heading-margin-bottom: 1rem;

// Individual stat
$impact-stat-min-width: auto; // Use this to control how many you get in a row
$impact-stat-margin: 0;

// Figure
$impact-stats-figure-colour: $brand-primary;
$impact-stats-figure-font-size: $font-size-h1;

// Summary
$impact-stats-summary-colour: $brand-secondary;
$impact-stats-summary-font-size: $font-size-small;

// Home Feeds
$home-feeds-padding-top: 50px;
$home-feeds-padding-bottom: 0;

// Header text (container for breadcrumb and page title)
$header-text-margin-top: 0;
$header-text-margin-bottom: 120px;

// Page title
$page-title-colour: $white;
$page-title-margin-bottom: 10px;

// Posts
$post-content-max-width: 1140px;

// Breadcrumb
$breadcrumb-margin-top: 0;
$breadcrumb-margin-bottom: 20px;

// Blockquote
$blockquote-max-width: 415px; // Either a px value or 100%
$blockquote-margin-y: 0;
$blockquote-background-colour: $bg-dark-secondary;
$blockquote-border-radius: 0;
$blockquote-text-align: left; // left, center, right
$blockquote-line-height: 1.4;
$blockquote-colour: $black;

// Share this page
$share-label-font-size: $font-size-h4;
$share-label-font-colour: $black;

// Sidebar
$sidebar-layout: below; // adjacent, below or above - where do you want the sidebar to be positioned on posts?

// Associated lists
$associated-latest-enabled: false;
$associated-related-enabled: true;
$associated-most-read-enabled: false;
$associated-max-number-of-posts: 3;
$associated-list-heading-font-size: $font-size-h2;
$associated-item-image-enabled: true;
$associated-item-heading-font-size: $font-size-h4;
$associated-item-summary-enabled: true;
$associated-item-border: false;

// Slick
$slick-enabled: true;
